import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { IApiCallStatus } from "../../../../common/interfaces";
import InputCtrl from "../../../../controls/InputCtrl";

const SignUp3 = ({ control, errorMessage, setSignup }: any) => {
  const history = useHistory();
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
    InProgress: false,
    Failed: false,
    FailMessage: "",
  });
  return (
    <>
      <div className="container">
        <div className="row d-flex align-items-cener">

          <div className="">
            <div className="logo text-center">
              <Link to="/">
                  <img src="/images/logo.svg" alt="logo" />
              </Link>
            </div>
            <div className="p-3">
                <div className="d-flex justify-content-between">
                  <h5>Sign Up</h5>
                  <h5>Step 3-7</h5>
                </div>
                <h6 className="my-3">
                  Don't have an account create an account it takes less a
                  minute
                </h6>
                <h2 className="my-3">Address Details </h2>
                <InputCtrl
                  control={control}
                  showError={errorMessage}
                  type="text"
                  placeholder="Address Line 1"
                  name="address1"
                  required={true}
                  className="control mb-6 my-3"
                  componentName="login"
                  disabled={ApiStaus.InProgress}
                />
                <InputCtrl
                  control={control}
                  showError={errorMessage}
                  type="text"
                  placeholder="Address Line 2"
                  name="address2"
                  required={false}
                  className="control mb-6 my-3"
                  componentName="login"
                  disabled={ApiStaus.InProgress}
                />
                <InputCtrl
                  control={control}
                  showError={errorMessage}
                  type="text"
                  placeholder="city"
                  name="city"
                  required={true}
                  className="control mb-6 my-3"
                  componentName="login"
                  disabled={ApiStaus.InProgress}
                />
                 <InputCtrl
                  control={control}
                  showError={errorMessage}
                  type="text"
                  placeholder="country"
                  name="country"
                  required={true}
                  className="control mb-6 my-3"
                  componentName="login"
                  disabled={ApiStaus.InProgress}
                />

                <label className="me-3">
                  <input type="checkbox"  className="round-checkbox me-2" 
                     onChange={(e) => {
                      setTerms(e.target.checked);
                      setTermsError(!e.target.checked);
                    }}
                  />I
                  hereby accept the terms and condition for the service
                </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp3;
